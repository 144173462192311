import { DialogServiceService } from "src/app/_app-core/dialogs/dialog-service.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { Config } from "../_store";
import { MenuItems } from "../_store/menuItems";
import { StringConstants } from "../_store/stringConstants";
import { AppConfigService } from "./appConfigService";
import { DateUtilsService } from "./dateUtils";
import { EventsService } from "./eventsService";
import { NavigationService } from "./navigation.service";
import { StorageService } from "./storage.service";
import { HttpUtilityService } from "./utility.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private userSessionString = "currentLoggedInUserUnifiedDashboard";
    private userSessionTimedOut = false;
    private lastActivityTime;

    constructor(
        private appConfigService: AppConfigService,
        private httpUtilityService: HttpUtilityService,
        private strorageService: StorageService,
        private eventsService: EventsService,
        private router: Router,
        private navigationService: NavigationService,
        private dateUtilsService: DateUtilsService,
        private dialogService: DialogServiceService
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(
            JSON.parse(this.getUserDataToSessionStorage())
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserData(): any {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string, token: string) {
        const req = {
            userName: username,
            token: token,
            password: password,
        };
        const loginUrl = `${this.appConfigService.appName}_LOGIN`;
        console.log(loginUrl);
        
        const url = this.appConfigService.urls[loginUrl]
            ? this.appConfigService.urls[loginUrl]
            : this.appConfigService.urls.LOGIN;
        console.log(url);
        
        return this.httpUtilityService.httpPost(req, url).pipe(
            map((data) => {
                return this.storeUserSessionData(data, username);
            })
        );
    }

    createPassword(password) {
        const userData = this.currentUserData;
        const req = {
            userName: userData.loginUsername,
            newPassword: password,
            session: userData.loginData.session,
        };

        return this.httpUtilityService.httpPost(
            req,
            this.appConfigService.urls.FORCECHANGEPASSWORD
        );
    }

    forgotPasswordRequest(email) {
        const req = {
            userName: email,
            authorization: "anN3X2FwaV9hY2Nlc3M6RF5xM2Zza1otTk4lbkRmKw==",
        };

        return this.httpUtilityService.httpPost(
            req,
            this.appConfigService.urls.FORGOTPASSWORD
        );
    }

    resetPassword(password, token, username) {
        const userData = this.currentUserData;
        const req = {
            userName: username,
            newPassword: password,
            token: token,
        };

        return this.httpUtilityService.httpPost(
            req,
            this.appConfigService.urls.RESETPASSWORD
        );
    }

    // validatePasswordCreationLink(linkId) {
    //     const req = {
    //         linkId,
    //     };
    //     return this.httpUtilityService
    //         .httpPost(req, this.appConfigService.urls.VALIDATE_PASSWORD_LINK)
    //         .pipe(
    //             map((data) => {
    //                 return data;
    //             })
    //         );
    // }

    // getMySubReportingLine(sellercode, username) {
    //     const req = {
    //         sellercode: sellercode,
    //         filters: [{ key: "orgName", value: username }],
    //     };

    //     const url = this.appConfigService.urls.GET_MY_SUB_REPORTING_LINE;
    //     return this.httpUtilityService.httpPost(req, url);
    // }
    // validatePIN(password: string) {
    //     const req = {
    //         password: password,
    //     };
    //     const url = this.appConfigService.urls.VERIFY_PASSWORD;
    //     return this.httpUtilityService.httpPost(req, url).pipe(
    //         map((data) => {
    //             return data;
    //         })
    //     );
    // }

    storeUserSessionData(data, username) {
        console.log();
        const respData = data.data || data.response || data;
        const user = respData.user || {};
        const uiConfig = user.uiConfig || {};
        const userData: any = {
            loginUsername: username,
            loginData: respData,
            userData: user,
            session: respData.session,
            onboarded: user.hasOnboarded,
            userId: user.displayName || user.sellerCode || "Test User",
            userName: user.displayName || user.sellerCode || "Test User",
            userRoles: (user.userRole || user.userRoles || "user").split(","),
            role: user.userRole,
            userTypes: (user.otherData || {}).userType || [],
            lowerOrgNm: [],
            forcePinChange: user.forcePinChange,
            defaultUrl: user.defaultUrl,
            sellerCodes: user.sellerCodes || [],
            isMultiSellerUser: typeof user.sellerCodes !== "undefined",
            profilePic: "",
            userActions: user.userActions || {},
            userLocale: user.localeUIConfig,
            userAllowedActions: uiConfig.userAllowedActions || [],
            userDescendants: user.userDescendants,
        };
        userData.selectedSellerCode = userData.sellerCodes[0];
        // userData.profilePic = `${this.appConfigService.urls.SELLER_LOGO}${
        //     userData.isMultiSellerUser
        //        / ? userData.sellerCodes[0].sellerCode
        //         : user.sellerCode
        // }.png`;
        const rolesConfig = {};
        const userRolesMap = userData.userRoles.reduce((userRoles, role) => {
            userRoles[role] = 1;
            Object.assign(rolesConfig, Config.rolesConfig[role] || {});
            return userRoles;
        }, {});
        userData.rolesConfig = rolesConfig;
        const appStateNames = {};
        let defaultUrl = "";
        // this.appConfigService.menuItems.forEach((menuItem) => {
        //     const stateAllowed = menuItem.roles.find(
        //         (role) => userRolesMap[role]
        //     );
        //     if (stateAllowed) {
        //         appStateNames[menuItem.stateKey] = 1;
        //         if (!defaultUrl && menuItem.routeUrl) {
        //             defaultUrl = menuItem.routeUrl;
        //         }
        //     }
        //     if (menuItem.children && menuItem.children.length) {
        //         menuItem.children.forEach((childMenuItem) => {
        //             const childStateAllowed = childMenuItem.roles.find(
        //                 (role) => userRolesMap[role]
        //             );
        //             if (childStateAllowed) {
        //                 appStateNames[childMenuItem.stateKey] = 1;
        //                 if (!defaultUrl && menuItem.routeUrl) {
        //                     defaultUrl = menuItem.routeUrl;
        //                 }
        //             }
        //         });
        //     }
        // });

        this.setUserDataToSessionStorage(userData);
        this.currentUserSubject.next(userData);
        userData.appStateNames = appStateNames;
        //userData.defaultUrl = defaultUrl || "/home";
        // this.getMySubReportingLine(user.sellerCode, username).subscribe(
        //     (resp) => {
        //         if (
        //             resp !== undefined &&
        //             resp !== null &&
        //             resp.success === true
        //         ) {
        //             userData.lowerOrgNm = (resp.data.list || []).map(
        //                 (val) => val._id
        //             );
        //             this.setUserDataToSessionStorage(userData);
        //         }
        //     }
        // );
        return userData;
    }

    changePassword(password: string, newpassword: string) {
        const userData = this.currentUserData;
        console.log(userData);
        const req = {
            userName: userData.loginUsername,
            password: password,
            newPassword: newpassword,
            session: userData.session,
        };
        return this.httpUtilityService
            .httpPost(req, this.appConfigService.urls.CHANGEPASSWORD)
            .pipe(
                map((data) => {
                    const respData = data.data || data.response || data;
                    if (respData.status === "success") {
                        this.resetForcePinChangeFlag();
                    }
                    return data;
                })
            );
    }

    logout(init = false, type = "") {
        this.lastActivityTime = undefined;
        if (!init) {
            this.resetUserSessionTimedout();
        }
        const clearSessionData = (logoutData: any = {}) => {
            this.clearUserSessionData();
            if (!init) {
                if (logoutData && logoutData.returnUrl) {
                    this.navigationService.locationNavigation(
                        logoutData.returnUrl
                    );
                } else {
                    setTimeout(() => {
                        this.navigationService.navigateToSignIn(true);
                    });
                }
            } else {
                setTimeout(() => {
                    this.navigationService.navigateToSignIn(true);
                });
            }
        };
        if (init) {
            clearSessionData({
                externalLoginUrl: Config.externalLoginUrl,
                type,
            });
        } else {
            return this.httpUtilityService
                .httpPost(
                    {
                        externalLoginUrl: Config.externalLoginUrl,
                    },
                    this.appConfigService.urls.LOGOUT
                )
                .pipe(
                    map((data) => {
                        return data.data || {};
                    })
                )
                .subscribe(
                    (data) => {
                        clearSessionData(data);
                    },
                    () => {
                        clearSessionData();
                    }
                );
        }
    }

    logoutWithoutModal(init = false, type = "") {
        const clearSessionData = (logoutData: any = {}) => {
            this.clearUserSessionData();
            if (!init) {
                if (logoutData && logoutData.returnUrl) {
                    this.navigationService.locationNavigation(
                        logoutData.returnUrl
                    );
                } else {
                    setTimeout(() => {
                        this.navigationService.navigateToSignIn(true);
                    });
                }
            } else {
                setTimeout(() => {
                    this.navigationService.navigateToSignIn(true);
                });
            }
        };
        if (init) {
            clearSessionData({
                externalLoginUrl: Config.externalLoginUrl,
                type,
            });
        } else {
            return this.httpUtilityService
                .httpPost(
                    {
                        externalLoginUrl: Config.externalLoginUrl,
                    },
                    this.appConfigService.urls.LOGOUT
                )
                .pipe(
                    map((data) => {
                        return data.data || {};
                    })
                )
                .subscribe(
                    (data) => {
                        clearSessionData(data);
                    },
                    () => {
                        clearSessionData();
                    }
                );
        }
    }

    clearUserSessionData() {
        sessionStorage.removeItem(this.userSessionString);
        this.strorageService.remove(
            Object.keys(StringConstants.STORAGE_KEYS).map(
                (key) => StringConstants.STORAGE_KEYS[key]
            )
        );
        this.clearUserDataFromSession();
        this.currentUserSubject.next(null);
        this.eventsService.triggerEvent(StringConstants.EVENTS.USER_LOGOUT, {
            userLoggedOut: true,
        });
    }

    getMenuItemsForUser() {
        const checkMenuAccessible = (stateKey) => {
            return typeof userAppStates[stateKey] !== "undefined";
        };
        const currentUser = this.currentUserData;
        const userAppStates = currentUser.appStateNames || [];
        const appMenuItems = this.appConfigService.menuItems;

        const allowedStates = {};
        const userMenuItems = appMenuItems.filter((menuItem) => {
            let isMenuAccessible = false;
            if (!menuItem.children) {
                menuItem.children = [];
            }
            // if (!menuItem.children.length) {
            //     if (
            //         currentUser.userData.sellerCode.toLowerCase() ===
            //         "transworld"
            //     ) {
            //         isMenuAccessible = checkMenuAccessible(menuItem.stateKey);
            //     } else {
            // if (menuItem.stateKey === "neft-it") {
            // } else {

            // }
            // isMenuAccessible = checkMenuAccessible(menuItem.stateKey);
            //}
            // } else {
            //     // check if any of the children is accessible
            //     const subMenuItems = menuItem.children.filter(
            //         (childMenuItem: any) => {
            //             const isAccessible = checkMenuAccessible(
            //                 childMenuItem.stateKey
            //             );
            //             childMenuItem.isAccessible = isAccessible;
            //             if (childMenuItem.isAccessible) {
            //                 allowedStates[childMenuItem.stateKey] = 1;
            //             }
            //             return isAccessible;
            //         }
            //     );
            //     isMenuAccessible = subMenuItems.length > 0;
            // }
            if (isMenuAccessible) {
                allowedStates[menuItem.stateKey] = 1;
            }
            return isMenuAccessible;
        });
        this.currentUserData.allowedStates = allowedStates;
        this.setUserDataToSessionStorage(this.currentUserData);
        return userMenuItems;
    }

    resetForcePinChangeFlag() {
        this.currentUserData.forcePinChange = undefined;
        this.setUserDataToSessionStorage(this.currentUserData);
        this.currentUserSubject.next(this.currentUserData);
    }

    private setUserDataToSessionStorage(userData) {
        this.strorageService.add(
            StringConstants.USER_sessionStr,
            JSON.stringify(userData)
        );
    }

    private getUserDataToSessionStorage() {
        return this.strorageService.get(StringConstants.USER_sessionStr);
    }

    private clearUserDataFromSession() {
        this.strorageService.remove([StringConstants.USER_sessionStr]);
    }

    userInRoles(rolesToCheck) {
        const userRolesMap = (this.currentUserData.userRoles || []).reduce(
            (userRoles, role) => {
                userRoles[role] = 1;
                return userRoles;
            },
            {}
        );
        return rolesToCheck.reduce((retVal, role) => {
            return retVal || (userRolesMap[role] ? true : false);
        }, false);
    }

    userInUserType(rolesToCheck) {
        const userRolesMap = (this.currentUserData.userTypes || []).reduce(
            (userRoles, role) => {
                userRoles[role] = 1;
                return userRoles;
            },
            {}
        );
        return rolesToCheck.reduce((retVal, role) => {
            return retVal || (userRolesMap[role] ? true : false);
        }, false);
    }

    userCanPerformAction(actionsToCheck) {
        const actionsMap = (
            this.currentUserData.userAllowedActions || []
        ).reduce((actions, action) => {
            actions[action] = 1;
            return actions;
        }, {});
        return actionsToCheck.reduce((retVal, action) => {
            return retVal || (actionsMap[action] ? true : false);
        }, false);
    }

    isUserReportingLine(userId) {
        const lowerOgNames: any[] = this.currentUserData.lowerOrgNm || [];
        return lowerOgNames.includes(userId);
    }
    getIsUserAllowedAction(actionToCheck) {
        return this.currentUserData.userActions[actionToCheck];
    }

    getSessionId() {
        if (this.currentUserData && this.currentUserData.session) {
            const session = (this.currentUserData || {}).session;
            return session.sessionId;
        }
    }

    changeSeller(sellerCode) {
        const req = {
            sellerCode: sellerCode,
        };
        const url = this.appConfigService.urls;
        return this.httpUtilityService.httpPost(req, url).pipe(
            map((data) => {
                // console.log(data);
                if (data && data.data && data.data.sessionId) {
                    this.currentUserData.session = {
                        sessionId: data.data.sessionId,
                    };
                    this.currentUserData.selectedSellerCode = {
                        sellerCode: data.data.selectedSellerCode,
                        //sellerName: ((this.currentUserData.sellerCodes[this.currentUserData.selectedSellerCode] || {}).sellerName) || data.data.selectedSellerCode
                    };
                    const sellerData =
                        this.currentUserData.sellerCodes.find(
                            (sellerCode) =>
                                sellerCode.sellerCode ===
                                data.data.selectedSellerCode
                        ) || {};
                    this.currentUserData.selectedSellerCode.sellerName =
                        sellerData.sellerName || data.data.selectedSellerCode;
                    this.setUserDataToSessionStorage(this.currentUserData);
                    this.currentUserSubject.next(this.currentUserData);
                    return true;
                }
                return false;
            })
        );
    }

    // getUserProfilePic(sellerCode: any = {}) {
    //     return sellerCode && sellerCode.sellerCode
    //         /? `${this.appConfigService.urls.SELLER_LOGO}${sellerCode.sellerCode}.png`
    //         : this.currentUserData.profilePic;
    // }

    checkLastActivityTime() {
        const currentTime = this.dateUtilsService.getCurrentTimeStamp();
        if (this.lastActivityTime) {
            const diff = currentTime - this.lastActivityTime;
            const timeoutVal = Config.sessionTimeout;
            if (diff >= timeoutVal) {
                this.userSessionTimedOut = true;
                // user session has timed out, logout user
                this.logoutWithoutModal(true, "SESSION_TIMED_OUT");
                setTimeout(() => {
                    this.userSessionTimedOut = false;
                }, 2000);
                return true;
            }
        }
        this.lastActivityTime = this.dateUtilsService.getCurrentTimeStamp();
    }

    setLastActivityTime() {
        this.lastActivityTime = this.dateUtilsService.getCurrentTimeStamp();
    }

    getIsUserSessionTimedout() {
        if (this.userSessionTimedOut) {
            return true;
        } else {
            return false;
        }
    }

    getSellerCode() {
        return this.currentUserData.loginData.user.sellerCode;
    }
    get isTTSL() {
        return this.currentUserData.loginData.user.sellerCode === "TTSL";
    }
    resetUserSessionTimedout() {
        this.userSessionTimedOut = false;
    }
}

import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/authenticationService";

@Component({
    selector: "app-topbar",
    templateUrl: "./topbar.component.html",
    styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
    notificationItems: Array<{}>;
    openMobileMenu: boolean;
    username = "";

    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {}

    ngOnInit() {
        // get the notifications
        this._fetchNotifications();
        // get the language
        //this.openMobileMenu = false;
        //
        const userData = this.authService.currentUserData;
        this.username =
            userData.displayName ||
            userData.userName ||
            sessionStorage.getItem("displayName");

        //this.username = "Test User";
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        console.log("clicked " + JSON.stringify(event));
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Logout the user
     */
    logout() {
        this.authService.logout();
        this.router.navigate(["/"]);
    }

    /**
     * Fetches the notification
     * Note: For now returns the hard coded notifications
     * Note: Dummy Code
     */
    _fetchNotifications() {
        this.notificationItems = [
            {
                text: "Sample Notification 1",
                subText: "1 min ago",
                icon: "mdi mdi-comment-account-outline",
                bgColor: "primary",
                redirectTo: "/notification/1",
            },
            {
                text: "Sample Notification 2",
                subText: "5 min ago",
                icon: "mdi mdi-account-plus",
                bgColor: "info",
                redirectTo: "/notification/2",
            },
            {
                text: "Sample Notification 3",
                subText: "Cool.",
                icon: "mdi mdi-comment-account-outline",
                bgColor: "success",
                redirectTo: "/notification/3",
            },
            {
                text: "Sample Notification 4",
                subText: "2 days ago",
                icon: "mdi mdi-comment-account-outline",
                bgColor: "danger",
                redirectTo: "/notification/4",
            },
            {
                text: "Sample Notification 5",
                subText: "1 min ago",
                icon: "mdi mdi-comment-account-outline",
                bgColor: "primary",
                redirectTo: "/notification/5",
            },
        ];
    }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";

import { User } from "./user.model";
import { Config } from "../../../_store/config";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    // user = new BehaviorSubject<User>(null);]
    isUnboarded = false;

    constructor(private http: HttpClient) {}

    isLoggedIn() {
        return !!localStorage.getItem("userSession");
    }

    signIn(data: any) {
        return this.http.post(Config.urls.LOGIN, data).pipe(
            catchError((errorRes) => {
                return throwError(errorRes);
            })
        );
    }

    fetchVendorMasterData(data: any) {
        return this.http.post(Config.urls.VENDORMASTERDATA, data);
    }

    forgotPassword(data: any) {
        return this.http.post(Config.urls.FORGOTPASSWORD, data);
    }

    resetPassword(data: any) {
        return this.http.post(Config.urls.RESETPASSWORD, data);
    }

    forceChangePassword(data: any) {
        return this.http.post(Config.urls.FORCECHANGEPASSWORD, data);
    }

    changePassword(data: any) {
        return this.http.post(Config.urls.CHANGEPASSWORD, data);
    }

    setOnboardingStatus(data: any) {
        this.isUnboarded = data;
    }

    getOnboardingStatus() {
        return this.isUnboarded;
    }

    autoLogout(expirationDate: number) {
        setTimeout(() => {
            this.logOut(localStorage.getItem("userSession"));
        }, expirationDate);
    }

    logOut(data: any) {
        return this.http.post(Config.urls.LOGOUT, data);
    }

    signUp() {}
}

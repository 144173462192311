import { environment } from "../../environments/environment";
import { MenuItems } from "./menuItems";

const urls = {
    // Auth
    LOGIN: `${environment.base}padd/app/login`,
    FORGOTPASSWORD: `${environment.base}payexap/api/forgottenPasswordReg`,
    CHANGEPASSWORD: `${environment.base}dashboard/resetPassword`,
    FORCECHANGEPASSWORD: `${environment.base}dashboard/resetPassword_ob`,
    RESETPASSWORD: `${environment.base}payexap/api/forgottenPassword`,
    LOGOUT: `${environment.base}logout`,
    VENDORMASTERDATA: `${environment.base}buyer/jsw/list`,
    RESENDEMAILS: `${environment.base}dashboard/onboardingEmailDispatch`,

    //DASHBOARD
    //ANALYTICS: `${environment.base}buyer/analytics`,
    VENDOR_ANALYTICS: `${environment.dataUrl}jsw/stats/vendor_analytics`,
    INVOICE_ANALYTICS: `${environment.dataUrl}jsw/stats/invoice_analytics`,
    UNIQUE_VENDORS_COUNT: `${environment.dataUrl}jsw/stats/vendors_with_invoices_count`,
    
    //REPORTS: `${environment.base}buyer/report`,
    REPORTS: `${environment.dataUrl}jsw/reports`,

    //VENDORS
    VENDORS: `${environment.base}buyer/jsw/list`,
    VENDORS_PAGED: `${environment.dataUrl}jsw/getVendorList`,
    VENDORDETAILS: `${environment.base}buyer/jsw/get`,

    //ADMINS
    LISTADMINS: `${environment.base}buyer/user/list`,
    ADDADMIN: `${environment.base}payexap/api/addbuyer`,
    CREATEADMIN: `${environment.base}buyer/createBuyers`,
    IMPERSONATE: `${environment.base}/buyer/imp`,
    ASSIGN: `${environment.base}/buyer/supplierToBuyerAssignment`,

    //INVOICES
    INVOICES: `${environment.base}dashboard/fetchInvoice`,
    EXTRACTINVOICE: `${environment.base}dashboard/extractInvoiceData`,
    REXTRACTINVOICE: `${environment.base}dashboard/extractInvoiceData`,
    SUBMITINVOICE: `${environment.base}dashboard/uploadInvoice`,
    SAVEINVOICE: `${environment.base}dashboard/saveInvoiceUploadProcess`,
    VERIFYSIGNATURE: `${environment.base}dashboard/verifyDigSignPdf`,
    SUBMITINVOICEWITHOUTEXTRACT: `${environment.base}dashboard/processInvoice_we`,
    SUBMITINVOICETOJSW: `${environment.base}dashboard/submitInvoiceToJSW`,

    //PURCHASE ORDERS
    PURCHASEODERS: `${environment.base}dashboard/fetchPo`,
    MATERIALPOS: `${environment.base}dashboard/fetchPod`,

    //CONFIG FOR ORGANIZATION
    FETCHCONFIG: `${environment.base}dashboard/fetchConfig`,
    FETCHCONFIGBUYER: `${environment.base}buyer/fetchConfig`,

    //CONFIG FOR VESON
    VESONINVOICEACTION: `${environment.base}buyer/actionOnInvoice`,
    VESONSUBMITINVOICE: `${environment.base}dashboard/fetchPod`,
    SHIPPINGINVOICES: `${environment.base}buyer/fetchInvoice`,
    SUBMITSUPPORTINGDOCS: `${environment.base}buyer/uploadSupportingDocs`,

    //ACCENTURE API CALLS

    // Auth
    // LOGIN: `${environment.API.PayEXDC.url}login`,
    // FORGOTPASSWORD: `${environment.API.PayEXDC.url}forgotPwd`,
    // CHANGEPASSWORD: `${environment.API.PayEXDC.url}dashboard/resetPassword`,
    // Lists
    // GETDOCS: `${environment.API.PayEXDC.url}/getDocuments`,
    // GET_INVOICES: `${environment.API.ES.url}invoiceservice/getInvoices`,
    // GET_BUYERS: `${environment.API.ES.url}buyerservice/getbuyers`,
    // ****** PADD *******//

    //PAYEXAP_LOGIN: `${environment.API.PayEXDC.url}padd/app/login`,
    // PADD - Landing Page Summary
    // PAYEXAP_LANDINGPAGE_SUMMARY: `${environment.BASE_URL}/dashboard/landingPageSummary`,
    // PAYEXAP_DASHBOARD: `${environment.BASE_URL}/dashboard/apStatusDashboard`,
    // PAYEXAP_SUPPLIER_ADMIN_DASHBOARD: `${environment.BASE_URL}/dashboard/payexapApp/supplierApStatusDashboard`,
    // PAYEXAP_DASHBOARD_GRAPH: `${environment.BASE_URL}/dashboard/apPaidVsOpenDashboard`,
    // PAYEXAP_SUPPLIER_ADMIN_DASHBOARD_GRAPH: `${environment.BASE_URL}/dashboard/payexapApp/supplierApPaidVsOpenDashboard`,
    // PADD - Sellers
    // PAYEXAP_GETSELLERS: `${environment.BASE_URL}/dashboard/admin/sellers/list`,
    // PAYEXAP_ADD_SELLER: `${environment.BASE_URL}/dashboard/admin/sellers/onboard`,
    // PAYEXAP_GET_SELLER: `${environment.BASE_URL}/dashboard/admin/sellers/view/`,
    // PAYEXAP_EDIT_SELLER: `${environment.BASE_URL}/dashboard/admin/sellers/modify/`,
    // PAYEXAP_GET_SELLER_LEDGER_SUMMARY: `${environment.BASE_URL}/dashboard/apLedger/summary/`,
    // PADD - Suppliers
    // PAYEXAP_GETSUPPLIERS: `${environment.BASE_URL}/dashboard/supplierList`,
    // PAYEXAP_ADD_SUPPLIER: `${environment.BASE_URL}/dashboard/supplier`,
    // PAYEXAP_GET_SUPPLIER: `${environment.BASE_URL}/dashboard/supplier/`,
    // PAYEXAP_EDIT_SUPPLIER: `${environment.BASE_URL}/dashboard/supplier/`,
    // PAYEXAP_UPLOAD_SUPPLIER: `${environment.BASE_URL}/dashboard/supplierUpload/`,
    // PADD - Invoices
    // PAYEXAP_GETINVOICES: `${environment.BASE_URL}/dashboard/apinvoice`,
    // PAYEXAP_SUPPLIER_GETINVOICES: `${environment.BASE_URL}/dashboard/payexapApp/supplierApinvoice`,
    // PAYEXAP_ADD_INVOICE: `${environment.BASE_URL}/dashboard/apinvoice`,
    // PAYEXAP_SUPPLIER_ADD_INVOICE: `${environment.BASE_URL}/dashboard/payexapApp/supplierApinvoice`,
    // PAYEXAP_GET_INVOICE: `${environment.BASE_URL}/dashboard/apLedger`,
    // PAYEXAP_SUPPLIER_GET_INVOICE: `${environment.BASE_URL}/dashboard/payexapApp/apLedger`,
    // PAYEXAP_EDIT_INVOICE: `${environment.BASE_URL}/dashboard/apLedger`,
    // PAYEXAP_UPLOAD_INVOICE: `${environment.BASE_URL}/dashboard/pdfReader`,
    // PAYEXAP_UPLOAD_INVOICE_TO_STORAGE_SERVER: `${environment.BASE_URL}/dashboard/apinvUpload`,
    // PAYEXAP_UPLOAD_EDIT_INVOICE: `${environment.BASE_URL}/dashboard/ledgerEditUpload`,
    // PAYEXAP_INVOICE_PAYMENT: `${environment.BASE_URL}/dashboard/apinvoicePayment`,
    // PAYEXAP_SUPPLIER_ADMIN_INVOICE_PAYMENT: `${environment.BASE_URL}/dashboard/payexapApp/apinvoicePayment`,
    // PAYEXAP_GET_SUPPLIER_BANK_INFO: `${environment.BASE_URL}/dashboard/supplierBank`,
    // PAYEXAP_GET_SELLER_BANK_INFO: `${environment.BASE_URL}/dashboard/admin/sellers/view/`,
    // PAYEXAP_PAYMENT: `${environment.BASE_URL}/dashboard/apPayment`,
    // PAYEXAP_DOWNLOAD_PAYMENT_INSTRUCTIONS: `${environment.BASE_URL}/dashboard/downloadApPayIns`,
    // PAYEXAP_UPLOAD_PAYMENT_RESPONSE: `${environment.BASE_URL}/dashboard/apPaymentUpload`,

    // PAYEXAP - Purchase Orders
    // PAYEXAP_GET_PURCHASE_ORDERS: `${environment.BASE_URL}/dashboard/po`,
    // PAYEXAP_SUPPLIER_ADMIN_GET_PURCHASE_ORDERS: `${environment.BASE_URL}/dashboard/payexapApp/supplierPo`,
    // PAYEXAP_UPLOAD_PURCHASE_ORDERS: `${environment.BASE_URL}/dashboard/poUpload`,

    // PAYEXAP - Purchase Orders
    // PAYEXAP_GET_PODs: `${environment.BASE_URL}/dashboard/pod`,
    // PAYEXAP_SUPPLIER_ADMIN_GET_PODs: `${environment.BASE_URL}/dashboard/payexapApp/supplierPod`,
    // PAYEXAP_UPLOAD_POD: `${environment.BASE_URL}/dashboard/podUpload`,

    // PAYEXAP - Recons
    // PAYEXAP_GET_RECONS: `${environment.BASE_URL}/dashboard/aprecon`,
    // PAYEXAP_UPLOAD_RECON_DISPUTE_RESOLUTION: `${environment.BASE_URL}/dashboard/apreconUpload`,
    // PAYEXAP_UPLOAD_INV_POD_RECON_DISPUTE_RESOLUTION: `${environment.BASE_URL}/dashboard/apreconInvUpload`,
    // PAYEXAP_GET_RECONS_DETAIL: `${environment.BASE_URL}/dashboard/offer`,
    // PAYEXAP_REQUEST_SUPPLIER_CN: `${environment.BASE_URL}/dashboard/apSendNoteRequest`,

    // PADD - Offers
    // PADD_GET_OFFERS: `${environment.API.PAYEXAP.url}dashboard/offer`,
    // PADD_GET_OFFER: `${environment.API.PAYEXAP.url}dashboard/offer`,
    // PADD_CREATE_OFFER: `${environment.API.PAYEXAP.url}dashboard/offer`,
    // PADD - Supplier - Offers
    // GENERATE_OTP: `${environment.API.PAYEXAP.url}padd/app/otp`,
    // PADD_GET_OFFER_LIST_FOR_SUPPLIER: `${environment.API.PAYEXAP.url}padd/app/offer`,
    // PADD_UPDATE_OFFER_BY_SUPPLIER: `${environment.API.PAYEXAP.url}padd/app/offer`,

    // PADD - Update Offers
    // PADD_LIST_UPDATEOFFERS: `${environment.API.PAYEXAP.url}dashboard/updateOffer`,
    // PADD_GET_UPDATEOFFERS: `${environment.API.PAYEXAP.url}dashboard/updateOffer`,
    // PADD_UPDATE_UPDATEOFFERS: `${environment.API.PAYEXAP.url}dashboard/updateOffer`,
};

const themeConfig = {
    Algoriq: {
        applyDarkTheme: true,
        title: "PayEX JSW Adin Portal",
        logo: "jsw-logo.png",
        statusMap: {
            SETTLED: { desc: "Settled through AlgoriQ" },
            FULLY_PAID_RND: { desc: "Perfect match with redundancy" },
        },
        NA_STATUS_LIST: {
            POSTED: 1,
            IN_BANK_QUEUE: 1,
            IN_BANK_PROC: 1,
            REJECTED: 1,
        },
        sortStatusList: [
            "ALL",
            "EXT_SETTLED",
            "SETTLED",
            "FULLY_PAID_RND",
            "FULLY_PAID",
            "PARTIALLY_PAID",
            "UNPAID",
            "POSTED_RND",
        ],
    },
    PayEXDC: {
        title: "JSW AP PORTAL - Admin Dashboard",
    },
};

const landingPageRouteConfig = {
    getsupplieroffer: { url: "app-flow/supplier" },
};

const pageSize = 15;

const productItemHeadings = {
    Seller: {
        single: "Seller",
        plural: "Sellers",
    },
};

const rolesConfig = {
    algoriq: {
        applyDarkTheme: true,
    },
};

export const Config = {
    urls,
    pageSize,
    menuItems: MenuItems.menuItems,
    dateDisplayFormat: "DD-MMM-YYYY",
    dateSaveFormat: "YYYY-MM-DD",
    dateServiceFormat: "DD-MM-YYYY",
    dateDisplayFormatWithTime: "YYYY-MM-DD HH:mm:ss",
    landingPageRouteConfig,
    appName: environment.appName,
    productItemHeadings,
    rolesConfig,
    themeConfig,
    externalLoginUrl: environment.externalLoginUrl,
    sessionTimeout: environment.environmentConfig.SESSION_TIME_OUT || 300000,
    deploymentInfo: environment.deploymentInfo,
};

import {
    Directive,
    HostBinding,
    HostListener,
    Output,
    EventEmitter,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Directive({
    selector: "[appDragNDrop]",
})
export class DragNDropDirective {
    @Output() fileDropped = new EventEmitter<any>();
    @HostBinding("style.background-color") private background = "#ffffff";
    // Dragover Event
    @HostListener("dragover", ["$event"]) dragOver(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.background = "#e2eefd";
    }
    // Dragleave Event
    @HostListener("dragleave", ["$event"]) public dragLeave(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.background = "#ffffff";
    }
    // Drop Event
    @HostListener("drop", ["$event"]) public drop(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.background = "#ffffff";
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }

    constructor() {}
}

import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminDataService } from "src/app/_services/admin.data.service";
import { AuthenticationService } from "src/app/_services/authenticationService";
import { DialogServiceService } from "../dialog-service.service";

@Component({
    selector: "app-impersonate-dialog",
    templateUrl: "./impersonate-dialog.component.html",
    styleUrls: ["./impersonate-dialog.component.scss"],
})
export class ImpersonateDialogComponent implements OnInit {
    @Input() title;
    @Input() message;
    constructor(
        public activeModal: NgbActiveModal,
        private adminService: AdminDataService,
        private authService: AuthenticationService,
        private dialogService: DialogServiceService
    ) {}

    ngOnInit(): void {}

    impersonate() {
        ///localStorage.clear();
        console.log("Trying to impersonate here");
        const userData = this.authService.currentUserData;
        const vendorCode = this.adminService.getImpersonateVendorCode();
        const reqData = {
            sellerCode: vendorCode,
            session: userData.loginData.session,
        };

        this.activeModal.close(false);
        this.dialogService.showWaitDialog();

        this.adminService.adminImpersonate(reqData).subscribe((data) => {
            if (data.status === "success" || data.status === "successful") {
                if (data.user.hasOnboarded === "true") {
                    window.location.href =
                        "https://jswap.payex.live/#/manage-user?token=" +
                        userData.loginData.session.sessionId +
                        "$$$" +
                        vendorCode +
                        "$$$" +
                        userData.userName +
                        "$$$" +
                        userData.loginUsername;
                } else {
                    console.log("user hasn't onboarded");
                }
            } else {
                this.dialogService.hideWaitDialog();
                this.dialogService.showInfoDialog("", data.msg);
                console.log(data);
            }
        });

        //console.log(reqData);
    }
}

import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationService } from "../_services";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (state.url.indexOf("/") !== -1) {
            return true;
        }
        const currentUser = this.authenticationService.currentUserData;
        if (currentUser) {
            const routeData = route.data || {};
            const stateKey = routeData.stateKey;
            if (
                stateKey &&
                stateKey !== "analytics" &&
                !routeData.skipRoleCheck &&
                currentUser.allowedStates
            ) {
                const userAccessingInvalidState =
                    !currentUser.allowedStates[stateKey];
                if (userAccessingInvalidState) {
                    this.router.navigate(["/analytics"]);
                }
            }
            if (currentUser.forcePinChange) {
                if (state.url.indexOf("/app-auth/change-password") === -1) {
                    this.router.navigate(["/app-auth/change-password"], {
                        queryParams: { returnUrl: state.url },
                    });
                }
            }
            return true;
        } else {
            this.router.navigate(["/"], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        }
    }
}

<div class="modal-header">
    <h4 class="text-white">Confirm</h4>
    <button
        type="button"
        class="modal-button close"
        aria-describedby="modal-title"
        (click)="activeModal.close(false)"
    >
        <i class="fa fa-times" aria-hidden="true"></i>
    </button>
</div>
<div class="modal-body">
    <p>
        <strong>{{ message }}</strong>
    </p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="activeModal.close(false)"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-danger"
        ngbAutofocus
        (click)="activeModal.close(true)"
    >
        Ok
    </button>
</div>

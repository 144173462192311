import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-info-dialog",
    templateUrl: "./info-dialog.component.html",
    styleUrls: ["./info-dialog.component.scss"],
})
export class InfoDialogComponent implements OnInit {
    @Input() title;
    @Input() message;
    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {}

    closeModal() {
        this.activeModal.close(false);
        window.location.reload();
    }
}

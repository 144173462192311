import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StringConstants } from "src/app/_store/stringConstants";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { InfoDialogComponent } from "./info-dialog/info-dialog.component";
import { WaitDialogComponent } from "./wait-dialog/wait-dialog.component";
import { ImpersonateDialogComponent } from "./impersonate-dialog/impersonate-dialog.component";

@Injectable({
    providedIn: "root",
})
export class DialogServiceService {
    private waitDialogInstance: NgbModalRef;
    invalidReasons: any;

    constructor(private modalService: NgbModal) {}

    showConfirmDialog(message) {
        const modalRef = this.modalService.open(ConfirmDialogComponent);
        modalRef.componentInstance.message = message;
        return modalRef.result;
    }

    showInfoDialog(title, message, size = "sm") {
        try {
            this.hideWaitDialog();
        } catch (err) {}
        const modalRef = this.modalService.open(InfoDialogComponent, {
            centered: true,
            backdrop: true,
            size,
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        return modalRef.result;
    }

    showWaitDialog() {
        this.waitDialogInstance = this.modalService.open(WaitDialogComponent, {
            centered: true,
            backdrop: "static",
            size: "sm",
        });
        return this.waitDialogInstance.result;
    }

    impersonateDialog(message, size = "sm") {
        const modalRef = this.modalService.open(ImpersonateDialogComponent, {
            centered: true,
            backdrop: true,
            size,
        });
        modalRef.componentInstance.message = message;
        return modalRef.result;
    }

    hideWaitDialog() {
        this.waitDialogInstance.close();
    }

    showServerError(key) {
        this.showInfoDialog(
            "Error",
            StringConstants.MESSAGES.SERVER_GENERAL_ERROR
        );
    }

    showErrorModal(errorMsg) {
        this.showInfoDialog("Error", errorMsg);
    }

    setReasons(reason: any) {
        this.invalidReasons = reason;
    }

    getReasons() {
        return this.invalidReasons;
    }
}

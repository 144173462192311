import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app.routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthLayoutComponent } from "./_layouts/authentication/authlayout.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BaseTheme } from "./_layouts/theme/basetheme.module";
import { CustomHttpInterceptor } from "./_services/customhttpinterceptor.service";
import { FullScreenComponent } from "./_layouts/full-screen/full-screen.component";
import { CommonModule } from "@angular/common";
import { DatePipe } from "@angular/common";

import { NgApexchartsModule } from "ng-apexcharts";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
//import { SortDirective } from "./_directives/sortable.directive";
import { PdfViewerModule } from "ng2-pdf-viewer";

//import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AppComponent } from "./app.component";
import { NavbarComponent } from "./_layout/theme/navbar/navbar.component";
import { SidebarComponent } from "./_layout/theme/sidebar/sidebar.component";
import { LoadersComponent } from "./_shared/ui/loaders/loaders.component";
import { LoaderComponent } from "./_shared/loader/loader.component";
import { SearchFilterPipe } from "./_pipes/search-filter.pipe";
import { OrderByPipe } from "./_app-core/pipes/order-by.pipe";
import { DragNDropDirective } from "./_directives/drag-n-drop.directive";

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        SidebarComponent,
        LoaderComponent,
        LoadersComponent,
        SearchFilterPipe,
        OrderByPipe,
        //SortDirective,
        DragNDropDirective,
        AuthLayoutComponent,
        FullScreenComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        NgApexchartsModule,
        RouterModule,
        NgxExtendedPdfViewerModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        PdfViewerModule,
        NgSelectModule,
        // Ng2SearchPipeModule,
        CommonModule,
        BrowserAnimationsModule,
        BaseTheme,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true,
        },
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
